import React, { useState } from "react";
import "../Pos/pos.css";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Scrollbars } from 'react-custom-scrollbars';

const CATEGORY_LIST = gql`
			query getCategoryList {
				category(id: 2) {
				  products {
					total_count
					page_info {
					  current_page
					  page_size
					}
				  }
				  children_count
				  children {
					id
					level
					name
					path
					children {
					  id
					  level
					  name
					  path
					  children {
						id
						level
						name
						path
						children {
						  id
						  level
						  name
						  path
						}
					  }
					}
				  }
				}
			  }
		`;


function CategoryFilter(props) {

	const { loading, error, data } = useQuery(CATEGORY_LIST);
	const [activeToggle, setActiveToggle] = useState('true');
	const [selectedCategory, setSelectedCategory] = useState('');
	const [activeMenu, setActiveMenu] = useState('false');
	function HiItems(items) {
		//console.log(items)
	}
	 
	
	function getChildern(children) {
		if (typeof children !== 'undefined' && children.length > 0) {
			return (
				<ul className="child-cat">
					{children.map((value, index) => {
						var categoryId = value.id;
						var categoryName = value.name;
						return (
							<li className="child-cat-inner" key={index}>
								<span onClick={() => filterProduct(categoryId, categoryName)} id={value.id} className="inner-child-title">{value.name}</span>
								<div className="second-level-cat">{getChildern(value.children)}</div>
							</li>
						);
					})}
				</ul>
			)
		}
	}

	function activeCategory() {
		setActiveToggle(!activeToggle);
		setActiveMenu(!activeMenu);
	}

	const filterProduct = (categoryId, categoryName) => {
		props.onChange(categoryId);
		setSelectedCategory(categoryName);
		setActiveToggle(!activeToggle);
		setActiveMenu(!activeMenu);
	}

	const showAllCategory = (e) => {
		props.onChange(0);
		setSelectedCategory('');
	}

	return (
		<div className="filter-area">
			<div className="product-head">
				<div className="categories">
					<span className={selectedCategory ? "category" : "category active"} onClick={showAllCategory}>All</span>
					{selectedCategory && (
						<span className="category active">{selectedCategory}</span>
					)}
				</div>
				<div className={activeMenu ? 'cat-toggle' : 'cat-toggle showMenu'} onClick={activeCategory}>
					<img alt="menu-toggle" src="images/menu-dropdown.png" width="20" height="20" />
				</div>
				<div className={activeToggle ? 'category-show' : 'category-show active'}>
					{data && data.category.children.map((value, index) => {
						var categoryId = value.id;
						var categoryName = value.name;
						return (
							<ul key={index} className="parent-cat-list">
								<li className="parent-cat" key={index}>
									<span className="parent-title" id={value.id} onClick={() => filterProduct(categoryId, categoryName)}>{value.name}</span>
									{getChildern(value.children)}
								</li>
							</ul>
						);
					})}
				</div>
			</div>
		</div>
	);
}
export default CategoryFilter;

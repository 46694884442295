import React from "react";
import ContentLoader from 'react-content-loader';

function cartSkeleton() {
  return (
        <ContentLoader
            height={200}
            width={305}
            speed={.8}
            primaryColor="#E8E8E8"
            secondaryColor="#FFFFFF"
        >
            <rect height="20" x="5" width="200" y="5" />
            <rect height="15" x="220" width="30" y="7" />
            <rect height="20" width="20" x="265" y="5" rx="50" />

            <rect height="20" x="5" width="200" y="30" />
            <rect height="15" x="220" width="30" y="32" />
            <rect height="20" width="20" x="265" y="30" rx="50" />

            <rect height="20" x="5" width="200" y="55" />
            <rect height="15" x="220" width="30" y="57" />
            <rect height="20" width="20" x="265" y="55" rx="50" />

            <rect height="20" x="5" width="200" y="80" />
            <rect height="15" x="220" width="30" y="82" />
            <rect height="20" width="20" x="265" y="80" rx="50" />

            <rect height="20" x="5" width="200" y="105" />
            <rect height="15" x="220" width="30" y="107" />
            <rect height="20" width="20" x="265" y="105" rx="50" />

            <rect height="20" x="5" width="200" y="130" />
            <rect height="15" x="220" width="30" y="132" />
            <rect height="20" width="20" x="265" y="130" rx="50" />

            <rect height="20" x="5" width="200" y="155" />
            <rect height="15" x="220" width="30" y="157" />
            <rect height="20" width="20" x="265" y="155" rx="50" />

            <rect height="20" x="5" width="200" y="180" />
            <rect height="15" x="220" width="30" y="182" />
            <rect height="20" width="20" x="265" y="180" rx="50" />

        </ContentLoader>
  		);
}
export default cartSkeleton;
